import { concat, isEqual } from 'lodash-es'
import SearchRoute from '../index'
import { suburbsToIds } from '../utils'

class EstimateRoute extends SearchRoute {
  constructor() {
    super()
  }

  reset() {
    this.region = []
    this.district = []
    this.suburb = []
    this.street = []
    this.houseId = null
    return this
  }

  isEqualSlug(slug) {
    const estimateRoute = getEstimateRoute()
    estimateRoute.fromSlug(slug)
    const slugIds = suburbsToIds(concat(estimateRoute.region, estimateRoute.district, estimateRoute.suburb, estimateRoute.street)).idStr()
    const selfIds = suburbsToIds(concat(this.region, this.district, this.suburb, this.street)).idStr()

    return (
      isEqual(slugIds, selfIds)
      && estimateRoute.houseId === this.houseId
    )
  }
}

export default function getEstimateRoute() {
  return new EstimateRoute()
}