import { NEW_ZEALAND } from '@config'
import { joinStr, slugToId, toNumber } from '@utils'
import { assign, each, forIn, get, isArray, isNumber, join, map, size, sortBy, split } from 'lodash-es'

/**
 * suburbs转ids
 * @param {Array} suburbs 
 * @returns ids
 */
export function suburbsToIds(suburbs) {

  let regionIds = []
  let districtIds = []
  let suburbIds = []
  let streetIds = []

  if (suburbs) {
    each(isArray(suburbs) ? suburbs : [suburbs], item => {
      if (item.id === NEW_ZEALAND.id) return
      switch (item.level) {
        case 'region':
          _push(regionIds, item.id)
          break
        case 'district':
          _push(districtIds, item.id)
          break
        case 'suburb':
          _push(suburbIds, item.id)
          break
        case 'street':
          _push(streetIds, item.id)
          break
      }
    })
  }
  regionIds = sortBy(regionIds)
  districtIds = sortBy(districtIds)
  suburbIds = sortBy(suburbIds)
  streetIds = sortBy(streetIds)


  return {
    ids() {
      return assign(
        _(regionIds, 'regionIds'),
        _(districtIds, 'districtIds'),
        _(suburbIds, 'suburbIds'),
        _(streetIds, 'streetIds'),
      )
    },
    idsStr() {
      return assign(
        _(regionIds, 'regionIds', true),
        _(districtIds, 'districtIds', true),
        _(suburbIds, 'suburbIds', true),
        _(streetIds, 'streetIds', true),
      )
    },
    id() {
      return assign(
        _(regionIds, 'regionId'),
        _(districtIds, 'districtId'),
        _(suburbIds, 'suburbId'),
        _(streetIds, 'streetId'),
      )
    },
    idStr() {
      return assign(
        _(regionIds, 'regionId', true),
        _(districtIds, 'districtId', true),
        _(suburbIds, 'suburbId', true),
        _(streetIds, 'streetId', true),
      )
    }
  }

  function _(list, key, isStr = false) {
    return size(list) > 0 ? { [key]: isStr ? joinStr(list, ',') : list } : undefined
  }

  function _push(list, id) {
    !isNaN(parseInt(id, 10)) && list.push(id)
  }
}

/**
 * ids转suburbs
 * @param {string|array} regionId
 * @param {string|array} districtId
 * @param {string|array} suburbId
 * @returns suburbs
 */
export async function idsToSuburbs({
  regionId,
  districtId,
  suburbId
}) {

  const httpClient = (await import('@apiClient')).default
  return fetchSuburbs({ regionId, districtId, suburbId })(httpClient)
}

/**
 * ids转suburbs
 * @param {string|array} regionId
 * @param {string|array} districtId
 * @param {string|array} suburbId
 * @returns suburbs
 */
export async function idsToSuburbsServer({
  regionId,
  districtId,
  suburbId
}) {

  const httpClient = (await import('@api')).default
  return fetchSuburbs({ regionId, districtId, suburbId })(httpClient)
}

/**
 * string转成query
 * @param {string} str alp_2_als_1,2
 * @returns query
 */
export function strToQuery(str) {
  let res = {}
  if (str) {
    const list = split(str, '_')
    for (let i = 0; i < list.length; i += 2) {
      const key = list[i]
      const val = list[i + 1]
      res[key] = map(split(val, ','), item => toNumber(slugToId(item)))
    }
  }
  return res
}

/**
 * query转成string
 * @param {object} query {alp: [2,3], als: 1}
 * @returns string
 */
export function queryToStr(query) {
  const res = []
  if (size(query)) {
    forIn(query, (val, key) => {
      if (size(val) > 0) res.push(join([key, isArray(val) ? join(val, ',') : val], '_'))
    })
  }
  return join(res, '_')
}


function fetchSuburbs(arg) {
  return async httpClient => {

    const regionIds = get(arg, 'regionId', get(arg, 'regionIds'))
    const districtIds = get(arg, 'districtId', get(arg, 'districtIds'))
    const suburbIds = get(arg, 'suburbId', get(arg, 'suburbIds'))

    return await httpClient.get('enum/locations/label', { regionIds, districtIds, suburbIds })
  }
}