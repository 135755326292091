import { get, isNull, isObject, isUndefined, pickBy } from 'lodash-es'
import { HUBPAGE_TYPE } from '@config'
import * as URLS from './config'
import getEstimateRoute from '@plugin/searchRoute/modules/estimate'
import { buildUrl } from '@plugin/http/lib/utils'
import { isCommercialData } from '@utils/house'
import { isBusinessesForSaleData } from '@utils/house'

export {
  URLS
}

function Router() {
  this.pathname
  this.query
  this.hash
  this.init = function _init(name, params, query, hash) {
    let route = URLS[name]
    let pathname = route ? (isObject(route) ? route.url : route) : name
    pathname = buildUrl(pathname, params)
    this.pathname = pathname
    this.query = pickBy(query, v => !(isNull(v) || isUndefined(v) || v === ''))
    this.hash = hash
    return this
  }
  this.get = function _get() {
    const { pathname, query, hash } = this
    return { pathname, query, hash }
  }
  this.href = function _href() {
    const { pathname, query } = this
    return buildUrl(pathname, undefined, query)
  }
}

export default function getRouter(name, params, query, hash) {
  return new Router().init(name, params, query, hash).get()
}

export function getRouterHref(name, params, query, hash) {
  return new Router().init(name, params, query, hash).href()
}

// 是否rural页面
export function isRuralPage(url) {
  return /\/rural/.test(url)
}
// 是否commercial页面
export function isCommercialPage(url) {
  return /\/commercial/.test(url)
}
// 是否businesses页面
export function isBusinesses(url) {
  return /\/businesses-for-sale/.test(url)
}


// ==============常用的路由==============
// 房源详情
export function houseDetail(data, query) {

  // 暂时不区分businesses commercial
  // // businesses detail
  // if (isBusinessesForSaleData(data)) {
  //   return getRouterHref(URLS.businessesDetail, { slug: get(data, 'slug') })
  // }

  // // commercial detail
  // if (isCommercialData(data)) {
  //   return getRouterHref(URLS.commercialDetail, { slug: get(data, 'slug') })
  // }

  // 普通房源
  return getRouterHref(URLS.houseDetail, { slug: get(data, 'slug') }, query)
}
// 新闻详情
export function newsDetail(data, prefix = 'news') {
  return getRouterHref(URLS.newsDetail, { prefix, slug: get(data, 'slug') })
}
// hubpage详情
export function hubpageDetail(data) {
  const { hubType, slug } = data
  switch (hubType) {
    case HUBPAGE_TYPE.insights:
      return getRouterHref(URLS.insightsHubpage, { hubSlug: slug })
    case HUBPAGE_TYPE.advice:
      return getRouterHref(URLS.adviceHubpage, { hubSlug: slug })
    case HUBPAGE_TYPE.news:
      return getRouterHref(URLS.newsHubpage, { hubSlug: slug })
    case HUBPAGE_TYPE.h:
      return getRouterHref(URLS.hHubpage, { hubSlug: slug })
  }
}
// 中介详情
export function agentDetail(data) {
  return getRouterHref(URLS.agentDetail, { slug: get(data, 'slug', get(data, 'id')) })
}
// 中介公司详情
export function agencyDetail(data) {
  return getRouterHref(URLS.agencyDetail, { slug: get(data, 'slug', get(data, 'id')) })
}
// 中介列表
export function agentList(searchType, slug, filter) {
  return getRouterHref(URLS.agentSearchList, { type: searchType, slug }, filter ? { filter } : undefined)
}
// 中介公司列表
export function officeList(searchType, slug) {
  return getRouterHref(URLS.officeSearchList, { type: searchType, slug })
}
// 找房列表
export function houseList(searchType, slug, query) {
  return getRouterHref(URLS.houseSearchList, { type: searchType, slug }, query)
}
// builder列表
export function builderList(slug, query) {
  return getRouterHref(URLS.builderSearchList, { slug }, query)
}
// builder详情
export function builderDetail(data) {
  return getRouterHref(URLS.builderDetail, { slug: get(data, 'slug') })
}
// 估价地图列表
export function estimateMap(slug, query) {
  return getRouterHref(URLS.estimateMap, { slug }, query)
}
// 估价地图列表
export function estimateMapSuburb(data) {
  const estimateRoute = getEstimateRoute()
  estimateRoute.setSuburbs(data)
  return estimateMap(estimateRoute.toSlug())
}
